export enum APIService {
  Auth = 'auth',
  Agencies = 'agencies',
  Groups = 'groups',
  Licenses = 'licenses',
  Users = 'users',
  Activities = 'events',
  Search = 'search',
  Cases = 'cases',
  AppSetting = 'appsetting',
  Permissions = 'permissions',
  PremiumFeatures = 'premiumFeatures',
  OnBoadring = 'onboarding'
}

export const AgencyTypes = {
  'Police Department': 'pd',
  'District Attorney': 'da',
  'Partner Agency': 'partner',
};

export const SupportAudioFormats = {
  'ORIGINAL': 'original',
  'WAV': 'wav',
  'MP3': 'mp3',
};
